<template>
  <el-card class="box-card">
    <el-radio-group v-model="timeType" style="margin-top:20px" @change="typeChange">
      <el-radio-button label="本周"></el-radio-button>
      <el-radio-button label="本月"></el-radio-button>
      <el-radio-button label="本年"></el-radio-button>
    </el-radio-group>
    <div ref="echartBox" style="width: 100%; height: 400px"></div>
  </el-card>
</template>

<script>
import { api } from '/src/api/base';
import { number_format } from '/src/filters/money.filter.js';
export const dashboardFlow = api()('dashboard.agent.flow.json');

export default {
  data () {
    return {
      timeType: '本周',
      tempData: null,
    };
  },

  async mounted () {
    await this.getData();
    this.setOption(this.tempData);

  },
  methods: {
    async getData () {
      const data = await dashboardFlow({
        type:
          this.timeType === '本周'
            ? 'week'
            : this.timeType === '本月'
              ? 'month'
              : 'year',
      });
      this.tempData = data;
    },
    setOption (data) {
      const list = data.list;
      var myChart = window.echarts.init(this.$refs.echartBox);
      var option = {
        title: {
          text: '企业结算流水统计',
          left: '12%',
          top: '3%',
          textStyle: {
            color: '#000000',
            fontWeight: 'bolder',
            fontSize: 17,
          },
        },
        tooltip: {},
        legend: {
          orient: 'vertical',
          right: '10%',
          data: ['结算金额', '服务费'],
          formatter: function (name) {
            if (name === '结算金额') {
              return name + '：' + number_format(data.total, 2);
            }
            // if (name === '服务费') {
            //   return name + '：' + data.totalFee;
            // }
          },
        },
        xAxis: {
          name: '日期',
          nameTextStyle: {
            color: '#595959',
          },
          axisTick: {
            alignWithLabel: true,
          },
          data: list.map((it) => it.date),
        },
        dataZoom: [
          {
            type: 'slider',
            startValue: list.map((it) => it.date)[0],
            endValue:
              list.length < 7
                ? list.map((it) => it.date)[list.length - 1]
                : list.map((it) => it.date)[9],
          },
        ],
        yAxis: {
          // nameLocation: "center",
          nameTextStyle: {
            align: 'center',
            color: '#595959',
          },
        },
        series: [
          {
            name: '结算金额',
            barWidth: '50px',
            barGap: '10%',
            type: 'bar',
            itemStyle: {
              color: '#5B8FF9',
              borderRadius: [25, 25, 0, 0],
            },
            data: list.map((it) => it.money),
          },
          // {
          //   name: '服务费',
          //   barWidth: '50px',
          //   type: 'bar',
          //   itemStyle: {
          //     color: '#7E57C2',
          //     borderRadius: [25, 25, 0, 0],
          //   },
          //   data: list.map((it) => it.fee),
          // },
        ],
      };
      myChart.setOption(option);
    },
    async typeChange () {
      await this.getData();
      this.setOption(this.tempData);
    },
  },
};
</script>

<style>
.el-radio-button__orig-radio:checked + .el-radio-button__inner {
  background-color: #73a0fa;
  border-color: #73a0fa;
}
.el-radio-button__inner:hover {
  color: #73a0fa;
}
</style>
